import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"
import { fetchUrl } from "../../../../ui/static_src/ui/utils/fetch"

class Briefings extends Controller {
  connect() {
    const hash = location.hash
    if (hash && !this.element.querySelector(hash)) {
      location.hash = hash.split("--")[0]
    }
  }
}

class Briefing extends Controller {
  static targets = ["section", "visibleSection"]
  static intersectionRootMargin = "-80px 0px -200px 0px"
  intersectionObserver = null

  connect() {
    if (this.hasSectionTarget) {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => entries.forEach((entry) => this.intersect(entry)),
        { rootMargin: Briefing.intersectionRootMargin },
      )
      this.sectionTargets.forEach((section) =>
        this.intersectionObserver.observe(section),
      )
    }
  }

  intersect(entry) {
    if (entry.isIntersecting) {
      entry.target.dataset.briefingTarget = "section visibleSection"
    } else {
      entry.target.dataset.briefingTarget = "section"
    }
    this.dispatch("section-change")
  }
}

class BriefingToc extends Controller {
  static targets = []
  static outlets = ["briefing"]
  static throttles = ["refreshToc"]
  static classCurrent = "-current"

  connect() {
    // By using a debounce, we keep the latest `section-change` event in a timeframe of 10ms.
    // This avoids refreshing the toc too often while providing fast feedback.
    useDebounce(this, { wait: 10 })
  }

  briefingOutletConnected(outlet, element) {
    setTimeout(() => this.refreshToc(), 100)
    element.addEventListener("briefing:section-change", () => this.refreshToc())
  }

  resetToc() {
    this.element
      .querySelectorAll(`.${BriefingToc.classCurrent}`)
      .forEach((element) => {
        element.classList.remove(BriefingToc.classCurrent)
        element.removeAttribute("aria-current")
        element.parentElement.removeAttribute("aria-expanded")
      })
  }

  refreshToc() {
    if (this.hasBriefingOutlet) {
      for (const briefing of this.briefingOutlets) {
        if (briefing.hasVisibleSectionTarget) {
          const firstVisibleSection = briefing.visibleSectionTarget
          const matchingSelector = `a[href="#${CSS.escape(
            firstVisibleSection.id,
          )}"]`
          const matchingTocLink = this.element.querySelector(matchingSelector)

          this.resetToc()
          matchingTocLink.classList.add(BriefingToc.classCurrent)
          matchingTocLink.parentElement.classList.add(BriefingToc.classCurrent)
          matchingTocLink.setAttribute("aria-current", "true")
          matchingTocLink.parentElement.setAttribute("aria-expanded", "true")
          history.replaceState(null, "", location.pathname + "#" + matchingTocLink.getAttribute("href").slice(1))

          break
        }
      }
    }
  }

  goToAnchor(event) {
    event.preventDefault()

    const anchorName = event.target.getAttribute("href").slice(1)
    const anchor = document.getElementById(anchorName) || document.body

    anchor.scrollIntoView({ behavior: "smooth", block: "start" })
    history.replaceState(null, "", location.pathname + "#" + anchorName)
  }
}

class BriefingHeader extends Controller {
  static targets = ["editionName"]
  static outlets = ["briefing"]
  static throttles = ["refreshHeader", "toggleVisibility"]
  static values = {
    isSticky: Boolean,
  }

  connect() {
    // By using a debounce, we keep the latest `section-change` event in a timeframe of 10ms.
    // This avoids refreshing the header too often while providing fast feedback.
    useDebounce(this, { wait: 10 })
  }

  briefingOutletConnected(outlet, element) {
    setTimeout(() => this.refreshHeader(), 100)
    element.addEventListener("briefing:section-change", () => this.refreshHeader())
    if (this.isStickyValue) {
      this.toggleVisibility()
      window.addEventListener("scroll", () => this.toggleVisibility())
    }
  }

  toggleVisibility() {
    this.element.classList.toggle("-hidden", window.scrollY < 10)
  }

  refreshHeader() {
    if (this.hasBriefingOutlet) {
      for (const briefing of this.briefingOutlets) {
        if (briefing.hasVisibleSectionTarget) {
          const firstVisibleSection = briefing.visibleSectionTarget
          const matchingSelector = `a[href="#${CSS.escape(
            firstVisibleSection.id.split("--")[0],
          )}"]`
          const matchingTocLink = this.element.querySelector(matchingSelector)
          this.editionNameTarget.textContent = matchingTocLink.textContent

          break
        }
      }
    }
  }

  goToAnchor(event) {
    event.preventDefault()

    const anchorName = event.target.getAttribute("href").slice(1)
    const anchor = document.getElementById(anchorName) || document.body

    anchor.scrollIntoView({ behavior: "smooth", block: "start" })
  }
}

class BriefingDatepicker extends Controller {
  showPicker(event) {
    try {
      event.target.showPicker()
    } catch { }
  }

  updateDate(event) {
    event.target.form.submit()
  }
}

class BriefitemToolbar extends Controller {
  static targets = ["mailto"]

  async openMailto(e) {
    e.preventDefault()
    const data = await fetchUrl(e.params.url)
    this.mailtoTarget.href = data.mailto
    this.mailtoTarget.click()
    this.mailtoTarget.removeAttribute("data-action")
  }
}

class BriefitemSummary extends Controller {
  static targets = ["summary"]
  static classSummary = ".briefitem-summary"
  static classBriefitem = ".briefitem-newscard"
  static classBriefitemWrap = ".briefitem-newscard__wrap-text"
  static classBriefitemContent = ".briefitem-newscard__content"
  static classBanner = ".layout-stage.-with-banner"
  static classHidden = "-hidden"
  static classAppearing = "-appearing"
  static classDisappearing = "-disappearing"
  static classLink = ["-underlined-colored-link", "-highlighted"]

  connect() {
    this.position()
    this.showSummaries()
  }

  showSummaries() {
    this.summaryTargets.forEach((summary) => {
      summary.classList.remove(BriefitemSummary.classHidden)
    })
  }

  position() {
    this.summaryTargets.forEach((summary) => {
      const briefitemId = this._getId(summary.id)
      const briefitem = document.getElementById(`briefitem-${briefitemId}`)
      if (!briefitem) return

      const rect = briefitem.getBoundingClientRect()
      let top = rect.top + window.scrollY
      const banner = document.getElementById(BriefitemSummary.classBanner)
      top -= banner ? banner.offsetHeight : 0
      summary.style.top = `${top}px`

      const briefitemWrap = briefitem.querySelector(BriefitemSummary.classBriefitemWrap)
      if (!briefitemWrap) return
      const briefitemContent = briefitem.querySelector(BriefitemSummary.classBriefitemContent)
      if (!briefitemContent) return

      const briefitemWrapComputedStyle = getComputedStyle(briefitemWrap)
      const gapValue = parseFloat(briefitemWrapComputedStyle.gap)
      const height = briefitemContent.offsetHeight
      summary.style.maxHeight = `${height + gapValue}px`
    })
  }

  show() {
    this.summaryTargets.forEach((summary) => {
      summary.classList.remove(BriefitemSummary.classDisappearing)
      summary.classList.add(BriefitemSummary.classAppearing)
    })
  }

  hide(e) {
    const briefitemId = this._getId(e.detail.briefItem.id)
    const summary = document.getElementById(`ref-briefitem-${briefitemId}`)
    if (!summary) return

    summary.classList.add(BriefitemSummary.classDisappearing)
    summary.classList.remove(BriefitemSummary.classAppearing)
  }

  highlight(e) {
    const link = this._findTargetedLink(e)
    if (!link) return
    link.classList.add(...BriefitemSummary.classLink)
  }

  unHighlight(e) {
    const link = this._findTargetedLink(e)
    if (!link) return
    link.classList.remove(...BriefitemSummary.classLink)
  }

  _findTargetedLink(e) {
    const target = e.currentTarget
    const briefitemSummary = target.closest("[id]")
    if (!briefitemSummary) return

    const briefitemId = this._getId(briefitemSummary.id)
    if (!briefitemId) return

    const briefitem = document.getElementById(`briefitem-${briefitemId}`)
    if (!briefitem) return

    const link = target.querySelector("a")
    const url = new URL(link.href)
    const pathname = url.pathname
    return briefitem.querySelector(`a[href*="${pathname}"]`)
  }

  _getId(value) {
    return value.split("-").pop()
  }
}

export { Briefing, BriefingDatepicker, BriefingHeader, Briefings, BriefingToc, BriefitemSummary, BriefitemToolbar }
