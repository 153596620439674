import { Controller } from "@hotwired/stimulus"
import { isMobile } from "../../utils/breakpoints.js"

const GO_BACK_TO_BRIEFITEM_PARAM = "go-back-to-briefitem"

class Previews extends Controller {
  static targets = ["preview"]
  static outlets = []
  static classHidden = "-hidden"
  static classAppearing = "-appearing"
  static classDisappearing = "-disappearing"
  static previewableLinkSelector = "*[href]:not(.-no-preview)"

  initialize() {
    this.isMobile = isMobile()
  }

  _previewFor(element) {
    if (!element) return

    return this.previewTargets.find((el) => {
      return el.dataset.for === element.getAttribute("href")
    })
  }

  showPreview(preview, element) {
    const linkRect = element.getBoundingClientRect()
    const top = linkRect.top + window.scrollY - element.closest(".layout-stage").offsetTop

    preview.style.setProperty("--js-link-top", `${Math.round(top)}px`)
    preview.classList.remove(Previews.classHidden, Previews.classDisappearing)
    preview.classList.add(Previews.classAppearing)
  }

  hidePreview(preview) {
    preview.classList.add(Previews.classDisappearing)
    setTimeout(() => {
      if (preview.classList.contains(Previews.classDisappearing)) {
        preview.classList.remove(Previews.classDisappearing)
        preview.classList.add(Previews.classHidden)
      }
    }, 200)
    preview.classList.remove(Previews.classAppearing)
  }

  hideAllPreviews({ keep = null } = {}) {
    for (const preview of this.previewTargets) {
      if (
        preview !== keep &&
        !preview.classList.contains(Previews.classHidden)
      ) {
        this.hidePreview(preview)
      }
    }
  }

  onNonlinkClicked() {
    if (this.isMobile) {
      this.hideAllPreviews()
    }
  }

  onLinkClicked(event) {
    if (event.detail.isCtrlClick) {
      return this.addGoBackToBriefitem(event)
    }

    if (!this.isMobile) {
      return
    }

    const preview = this._previewFor(event.detail.link)

    // If there is no preview to show, or if the preview is already shown,
    // return without preventing the default so the link is followed
    if (!preview || !preview.classList.contains(Previews.classHidden)) {
      return
    }

    event.preventDefault()
    this.hideAllPreviews({ keep: preview })
    this.showPreview(preview, event.detail.link)
  }

  openPreviewLink(event) {
    const isPreview = event.target.matches("[data-for]")
    const preview = isPreview
      ? event.target
      : event.target.closest("[data-for]")

    if (!preview) {
      return
    }

    const dataFor = preview.getAttribute("data-for")
    const link = this.element.querySelector(`a[href=${CSS.escape(dataFor)}`)

    if (!link) {
      return
    }

    event.preventDefault()
    event.stopPropagation() // Prevents onNonlinkClicked to be fired
    link.click()
  }

  addGoBackToBriefitem(event) {
    const link = event.detail.link
    const initialHref = link.getAttribute("href")
    const url = new URL(link.href)
    const isSameOrigin = url.origin === window.location.origin
    if (isSameOrigin) {
      const closestBriefitem = link.closest(".briefitem-newscard")
      if (closestBriefitem) {
        const briefitemId = closestBriefitem.id.split("-")[1]
        // Add the go-back-to-briefitem param to the link
        url.searchParams.set(GO_BACK_TO_BRIEFITEM_PARAM, briefitemId)
        link.href = url
        // Restore href after the click event has been processed
        setTimeout(() => {
          link.setAttribute("href", initialHref)
        }, 0)
      }
    }
    return event
  }

  _getDesktopPreviewTarget(event) {
    if (this.isMobile) {
      return
    }

    const isLink = event.target.matches(Previews.previewableLinkSelector)
    const target = isLink ? event.target : event.target.closest(Previews.previewableLinkSelector)

    if (!target) {
      return
    }

    return target
  }

  onMouseOver(event) {
    const target = this._getDesktopPreviewTarget(event)
    const preview = this._previewFor(target)
    if (!preview) return

    this.hideAllPreviews()
    this.showPreview(preview, target)
  }

  onMouseOut(event) {
    const target = this._getDesktopPreviewTarget(event)
    const preview = this._previewFor(target)

    if (!preview) {
      return
    }

    this.hidePreview(preview)
  }

  previewTargetConnected(elt) {
    const preview = elt
    this.element.querySelectorAll(`a[href=${CSS.escape(preview.dataset.for)}]`).forEach((elt) => {
      elt.setAttribute("aria-describedby", preview.id)
    })
  }
}

class BriefingPreviews extends Previews {
  static briefItemSelector = ".briefitem-newscard"
  static outOfBriefingContentSelector = ".layout-header,.left-column"
  static previewSelector = ".preview-block"

  initialize() {
    super.initialize()
    this.hoveredBriefItem = null
  }

  showPreview(preview, element) {
    const linkRect = element.getBoundingClientRect()
    const top = linkRect.top + window.scrollY - element.closest(".layout-stage").offsetTop
    preview.style.setProperty("--js-link-top", `${Math.round(top)}px`)
    preview.classList.remove(Previews.classHidden)
  }

  hidePreview(preview) {
    preview.classList.add(Previews.classHidden)
  }

  onNonlinkClicked({ target }) {
    if (this.isMobile && !this._mouseInPreview(target)) {
      this.hideAllPreviews()
    }
  }

  onMouseOver(event) {
    const briefItem = this._getClosestBriefItem(event)
    const target = this._getDesktopPreviewTarget(event)
    const preview = this._previewFor(target)

    // hide preview if :
    // - mouse is a link but not the one with its preview displayed
    // - mouse is in a briefitem but not the one currently hovered
    // - or mouse is outside the briefing content (aka in summary or header)
    const isLinkFromBriefitem = event.target.matches(`${BriefingPreviews.briefItemSelector} *[href]`)

    if (
      (isLinkFromBriefitem && !this._isPreviewAlreadyDisplayed(preview)) ||
      this._isNewBriefItem(briefItem) ||
      this._mouseOutOfBriefingContent(event)
    ) {
      this.hideAllPreviews()
      this.hoveredBriefItem && this.dispatch("previewHidden")
    }

    if (!preview) return

    if (isLinkFromBriefitem || this._isPreviewDisplayed()) {
      this.dispatch("previewDisplayed", { detail: { briefItem } })
    }

    isLinkFromBriefitem && this.showPreview(preview, target)
    if (briefItem) this.hoveredBriefItem = briefItem
  }

  openPreviewLink(event) {
    const closestLink = event.target.closest("[data-for],a,[hx-get],[hx-post],[hx-delete]")
    const noLinkClickedInPreview = closestLink.matches("[data-for]")
    if (noLinkClickedInPreview) super.openPreviewLink(event)
  }

  onMouseOut(event) {}

  _getClosestBriefItem(event) {
    return event.target.closest(BriefingPreviews.briefItemSelector)
  }

  _mouseOutOfBriefingContent(event) {
    /**
     * Briefing content being on main and right columns,
     * detect if the mouse is somewhere else possible
     */
    return !!event.target.closest(BriefingPreviews.outOfBriefingContentSelector)
  }

  _mouseInPreview(target) {
    return !!target.closest(BriefingPreviews.previewSelector)
  }

  _isPreviewAlreadyDisplayed(preview) {
    return preview && !preview.classList.contains(Previews.classHidden)
  }

  _isPreviewDisplayed() {
    const previews = this.previewTargets.filter(preview => !preview.classList.contains(Previews.classHidden))
    return previews.length > 0
  }

  _isNewBriefItem(briefItem) {
    return briefItem && this.hoveredBriefItem?.id !== briefItem.id
  }
}

export { BriefingPreviews, Previews }
